export default [
  {
    header: 'Inicio',
    resource: 'Dashboard',
    action: 'read',
  },
  {
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'HomeIcon',
    resource: 'Dashboard',
    action: 'read',
  },
]
