export default [
  {
    header: 'E-commerce',
    resource: ['Ecommerce'],
    action: 'read',
  },
  {
    title: 'Producto',
    route: 'mercancia',
    icon: 'HomeIcon',
    resource: 'Ecommerce',
    action: 'read',
  },
]
