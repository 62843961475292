export default [
  {
    header: 'Producción',
    resource: ['Categories', 'Products', 'Circuits', 'Transfers', 'Import'],
    action: 'read',
  },
  {
    title: 'Catálogo',
    route: 'catalog',
    icon: 'BookOpenIcon',
    resource: 'Categories',
    action: 'read',
  },
  {
    title: 'Productos',
    route: 'products-home',
    icon: 'BoxIcon',
    resource: 'Products',
    action: 'read',
  },
  {
    title: 'Circuitos',
    route: 'circuits-home',
    icon: 'LayersIcon',
    resource: 'Circuits',
    action: 'read',
  },
  {
    title: 'Traspasos',
    route: 'transfers-list',
    icon: 'RefreshCwIcon',
    resource: 'Transfers',
    action: 'read',
  },
  {
    title: 'Importación',
    route: 'imports-home',
    icon: 'TagIcon',
    resource: 'Import',
    action: 'read import_numbers',
  },
]
