<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div @click="checkPasswordEmail()">
            <div class="d-sm-flex d-none user-nav">
              <p class="user-name font-weight-bolder mb-0">
                {{ userData.Name || userData.LastName }}
              </p>
              <span class="user-status">{{ userData.Role }}</span>
            </div>
            <b-avatar
              size="40"
              variant="light-primary"
              badge
              :src="userData.avatar"
              class="badge-minimal"
              badge-variant="success"
            />
          </div>
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          :disabled="loadingData"
          @click="isSidebarOpen = true"
        >
          <feather-icon
            size="16"
            icon="MailIcon"
            class="mr-50"
          />
          <span>Configurar correo</span>
          <b-badge
            v-if="!passwordEmail && !loadingData"
            pill
            variant="danger"
            class="ml-1"
          >
            !
          </b-badge>
          <b-spinner
            v-if="loadingData"
            class="ml-1"
            small
          />
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Salir</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>

    <b-sidebar
      id="sidebar-right"
      v-model="isSidebarOpen"
      bg-variant="white"
      width="33em"
      :no-close-on-backdrop="true"
      right
      backdrop
      no-header
      shadow
    >
      <EmailTestForm
        v-if="loadEmailTestForm && !loadingData"
        ref="emailTestForm"
        :user-data="userData"
        :password-email="passwordEmail || ''"
        @on:close="isSidebarOpen = false"
      />
    </b-sidebar>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider,
  BAvatar, BBadge, BSidebar, BSpinner,
} from 'bootstrap-vue'

import useJwt from '@/auth/jwt/useJwt'
import { initialAbility } from '@/libs/acl/config'
import { avatarText } from '@core/utils/filter'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import EmailTestForm from '@/views/pages/authentication/EmailTestForm.vue'
import getError from '@/helpers/ErrorsHandler'

export default {
  components: {
    BLink,
    BBadge,
    BAvatar,
    BSidebar,
    BSpinner,
    BNavbarNav,
    BDropdownItem,
    EmailTestForm,
    BNavItemDropdown,
    BDropdownDivider,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      avatarText,
      isSidebarOpen: false,
      passwordEmail: null,
      loadingData: false,
      loadEmailTestForm: false,
    }
  },
  methods: {
    ...mapActions({
      clearFilteredData: 'filters/clearFilteredData',
    }),
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })

      this.clearFilteredData()
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
    checkPasswordEmail() {
      this.loadingData = true
      this.loadEmailTestForm = false

      useJwt.checkEmailConfig()
        .then(response => {
          this.passwordEmail = response.data.data[0].PasswordEmail
        })
        .catch(error => {
          this.showToast('Error de validación', getError(error), 'danger')
          this.passwordEmail = null
        })
        .finally(() => {
          this.loadingData = false
          this.loadEmailTestForm = true
        })
    },
  },
}
</script>
