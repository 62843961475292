export default [
  {
    header: 'Comercial',
    resource: ['Quotes', 'Orders', 'Commissions'],
    action: 'read',
  },
  {
    title: 'Cotizaciones',
    route: 'quotes-home',
    icon: 'FileTextIcon',
    resource: 'Quotes',
    action: 'read',
  },
  {
    title: 'Órdenes',
    route: 'orders-home',
    icon: 'ShoppingBagIcon',
    resource: 'Orders',
    action: 'read',
  },
  {
    title: 'Comisiones',
    route: 'commissions-home',
    icon: 'PercentIcon',
    resource: 'Commissions',
    action: 'read',
  },
]
