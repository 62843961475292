<template>
  <b-container class="mt-2 px-2">
    <validation-observer ref="emailTestForm">
      <b-form @submit="$event.preventDefault()">
        <b-form-row>

          <!-- #region::Title and close button -->
          <b-col
            md="12"
            class="align-self-center"
          >
            <div class="d-flex justify-content-between">
              <h3 class="align-self-end">
                Configurar correo
              </h3>
              <b-button
                variant="flat-secondary"
                class="btn-icon rounded-circle"
                @click="onClose"
              >
                <feather-icon icon="XIcon" />
              </b-button>
            </div>
          </b-col>
          <!-- #endregion::Title and close button -->

          <b-col md="12">
            <hr>
          </b-col>

          <b-col md="12">
            <b-alert
              show
              variant="info"
            >
              <div class="alert-body">
                <feather-icon
                  class="mr-25"
                  icon="AlertCircleIcon"
                />
                <span class="ml-25">Configura tu correo para que tus mensajes se envíen desde ahí.</span>
              </div>
            </b-alert>
          </b-col>

          <!-- #region::Email input -->
          <b-col md="12">
            <TextInputWithValidation
              v-model="userEmail"
              vid="userEmail"
              type="text"
              label="Correo electrónico"
              name="correo electrónico"
              append="@optimumequipo.com"
              placeholder="Escribe el correo electrónico"
              :disabled="true"
              :append-disabled="true"
            />
          </b-col>
          <!-- #endregion::Email input -->

          <!-- #region::Output email input -->
          <b-col md="12">
            <TextInputWithValidation
              v-model="outputEmail"
              vid="outputEmail"
              rules="required|email"
              type="text"
              label="Correo de salida"
              name="correo de salida"
              placeholder="Escribe el correo electrónico"
            />
          </b-col>
          <!-- #endregion::Output email input -->

          <!-- #region::Password input -->
          <b-col md="12">
            <!-- password -->
            <b-form-group
              label="Contraseña"
              label-for="password"
            >
              <validation-provider
                #default="{ errors }"
                name="contraseña"
                vid="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="password1FieldType"
                    class="form-control-merge"
                    name="password"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password1ToggleIcon"
                      @click="togglePassword1Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <b-alert
                  variant="danger"
                  :show="errors.length > 0 ? true:null"
                  class="mb-0 mt-1"
                >
                  <div class="alert-body">
                    <small>{{ errors[0] }}</small>
                  </div>
                </b-alert>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- #endregion::Password input -->

          <b-col md="12">
            <p><small class="text-muted">Para que tu correo pueda ser utilizado para enviar emails desde la aplicación debes habilitar el permiso de aplicaciones menos segura:</small></p>

            <p><small>
              <a
                href="https://myaccount.google.com/lesssecureapps"
                target="_blank"
                rel="noopener"
              >https://myaccount.google.com/lesssecureapps
              </a>
            </small></p>

            <p><small class="text-muted">Recuerda tener iniciada la sesión con tu correo @optimumequipo.com</small></p>
          </b-col>

          <b-col md="12">
            <b-button
              class="mb-1"
              variant="flat-primary"
              :disabled="sendingTestEmail"
              @click="onSendEmail"
            >
              <b-spinner
                v-if="sendingTestEmail"
                class="mr-50"
                small
              />
              Enviar correo de prueba
            </b-button>
            <p><small class="text-secondary">Envía el correo para verificar que todo funciona correctamente.</small></p>
          </b-col>

          <!-- #region::Seller save button -->
          <b-col md="6">
            <b-button
              variant="principal-btn"
              class="my-1 my-md-0 w-100 principal-btn"
              @click="onSave"
            >
              Guardar cambios
            </b-button>
          </b-col>
          <!-- #endregion::Seller save button -->

          <!-- #region::Cancel save button -->
          <b-col md="6">
            <b-button
              variant="delete-btn"
              class="my-lg-0 w-100 delete-btn"
              @click="onClose"
            >
              Cancelar
            </b-button>
          </b-col>
          <!-- #endregion::Cancel save button -->

        </b-form-row>
      </b-form>
    </validation-observer>
  </b-container>
</template>

<script>
// #region Imports
import { required, email } from '@validations'
import useJwt from '@/auth/jwt/useJwt'
import {
  BContainer, BForm, BFormRow, BCol, BButton, BAlert, BFormGroup, BInputGroup,
  BFormInput, BInputGroupAppend, BSpinner,
} from 'bootstrap-vue'

import { ValidationObserver, ValidationProvider, localize } from 'vee-validate'
import TextInputWithValidation from '@/components/forms/TextInputWithValidation.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import es from 'vee-validate/dist/locale/es.json'
import getError from '@/helpers/ErrorsHandler'
// #endregion

export default {
  components: {
    BCol,
    BForm,
    BAlert,
    BButton,
    BSpinner,
    BFormRow,
    BFormGroup,
    BContainer,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    ValidationObserver,
    ValidationProvider,
    TextInputWithValidation,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    passwordEmail: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      password: '',
      userEmail: '',
      outputEmail: '',

      // * 24/11/2022 - TAG: Validations
      required,
      email,

      // * 24/11/2022 - TAG: Toggle Password
      password1FieldType: 'password',
      sendingTestEmail: false,
    }
  },
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  created() {
    this.userEmail = this.userData.Email.substring(0, this.userData.Email.indexOf('@'))
    this.password = this.passwordEmail
    localize('es', es)
  },
  methods: {
    onSave() {
      this.$refs.emailTestForm.validate().then(success => {
        if (success) {
          useJwt.updateEmailPassword({
            passwordEmail: this.password,
          })
            .then(response => {
              this.showSwalFire(`¡${response.data.message}!`)
            })
            .catch(error => {
              this.showToast('Error de validación', 'El correo electrónico y/o contraseña es incorrecto.', 'danger')
              this.$refs.emailTestForm.setErrors(error.response.data.error)
            })
        }
      })
    },
    onSendEmail() {
      this.$refs.emailTestForm.validate().then(success => {
        if (success) {
          this.sendingTestEmail = true

          useJwt.sendTestEmail({
            passwordEmail: this.password,
            emailTest: this.outputEmail,
          })
            .then(response => {
              console.log(response)
              this.sendingTestEmail = false
              this.showToast('Correo enviado', 'El correo electrónico ha sido enviado correctamente.', 'success')
            })
            .catch(error => {
              this.sendingTestEmail = false
              this.showToast('Error de validación', getError(error), 'danger')
              this.$refs.emailTestForm.setErrors(error.response.data.error)
            })
        }
      })
    },
    onClose() {
      this.password = ''
      this.outputEmail = ''

      this.$refs.emailTestForm.reset()
      this.$emit('on:close')
    },
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    showSwalFire(message) {
      this.$swal.fire({
        title: 'Información actualizada',
        text: message,
        icon: 'success',
        showCancelButton: false,
        confirmButtonText: 'Continuar',
        allowEscapeKey: false,
        allowOutsideClick: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.onClose()
        }
      })
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
