export default [
  {
    header: 'Reportes',
    resource: ['Reports'],
    action: 'read',
  },
  {
    title: 'Inventario',
    route: 'suppliers-home',
    icon: 'TruckIcon',
    resource: 'Reports',
    action: 'read',
  },
  {
    title: 'Pagos',
    route: 'payments-home',
    icon: 'CreditCardIcon',
    resource: 'Payments',
    action: 'read all',
  },
]
