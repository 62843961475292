export default [
  {
    header: 'Almacén',
    resource: ['Inventory_entries', 'Out_inventories', 'Devolution'],
    action: 'read',
  },
  {
    title: 'Entradas',
    route: 'incomes',
    icon: 'DownloadIcon',
    resource: 'Inventory_entries',
    action: 'read',
  },
  {
    title: 'Salidas',
    route: 'outcomes',
    icon: 'UploadIcon',
    resource: 'Out_inventories',
    action: 'read',
  },
  {
    title: 'Devoluciones',
    route: 'refunds-list',
    icon: 'ExternalLinkIcon',
    resource: 'Devolutions',
    action: 'read',
  },
]
