export default [
  {
    header: 'Usuarios',
    resource: ['Users_company', 'Wholesalers', 'Clients', 'Sellers'],
    action: 'read',
  },
  {
    title: 'Empresa',
    route: 'company-home',
    icon: 'TrelloIcon',
    resource: 'Users_company',
    action: 'read',
  },
  {
    title: 'Mayorista',
    route: 'wholesalers-home',
    icon: 'ArchiveIcon',
    resource: 'Wholesalers',
    action: 'read',
  },
  {
    title: 'Vendedores',
    route: 'sellers-home',
    icon: 'DollarSignIcon',
    resource: 'Sellers',
    action: 'read',
  },
  {
    title: 'Clientes',
    route: 'customers-home',
    icon: 'UsersIcon',
    resource: 'Clients',
    action: 'read',
  },
]
